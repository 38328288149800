import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Notifications from '@kyvg/vue3-notification'
import EcFooter from './components/ecfooter'

const app = createApp(App)
app.use(VueAxios, axios)
app.use(router)
app.use(Notifications)
app.component('EcFooter', EcFooter)

app.mixin({
  methods: {
    gen_random_string: function(len) {
      var l = len || 10;
      var r = '';

      while (r.length < l) {
        var a = Math.random().toString(36).substring(2, 15);
        r = r + a;
      }
      return r.substring(0, l);
    },
    display_field: function(val, field) {
      if (field.metadata.type == 'datetime') {
        return this.format_timestamp(val);
      }
      if (field.name == 'succeeded') {
        return val ? "成功" : "失败";
      }
      if (field.metadata.type == 'boolean') {
        return val ? "是" : "否";
      }
      return val;
    },
    format_timestamp: function(ts, dateonly) {
      var d = new Date(ts * 1000);
      if (dateonly) {
        return d.toLocaleDateString();
      } else {
        return d.toLocaleString();
      }
    },
    sleep: function(ms) {
      return new Promise((accept) => {
        setTimeout(accept, ms);
      });
    },
  },
})

app.mount('#app')
