<template>
  <div class="ecfooter">
    <div>
      Euphon Cloud - v2024.02
    </div>
    <div>
      Copyright 2023-2024 Euphon Tech Ltd. Shanghai (CN). All rights reserved.
    </div>
    <div>
      <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备20013670号-6</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EcFooter',
  props: [],
  components: {
  },
  data: function () {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
  mounted() {
  },
}
</script>

<style scoped>
div.ecfooter {
  position: fixed;
  bottom: 0;
  text-align: center;
  font-size: 0.8rem;
  width: 100%;
  color: #888;
  padding-bottom: 0.5rem;
}
</style>
