import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'euchat',
    component: () => import(/* webpackChunkName: "euchat" */ '../views/euchat.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "search" */ '../views/search.vue')
  },
  {
    path: '/eai',
    name: 'eai',
    component: () => import(/* webpackChunkName: "eai" */ '../views/eai.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import(/* webpackChunkName: "change-password" */ '../views/change-password.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/register.vue')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/reset-password.vue')
  },
  {
    path: '/nodes',
    name: 'nodes',
    component: () => import(/* webpackChunkName: "nodes" */ '../views/nodes.vue')
  },
  {
    path: '/node/:machine_id',
    name: 'node',
    props: true,
    component: () => import(/* webpackChunkName: "node" */ '../views/node.vue')
  },
  {
    path: '/node/:machine_id/log',
    name: 'node-log',
    props: true,
    component: () => import(/* webpackChunkName: "node-log" */ '../views/node-log.vue')
  },
  {
    path: '/node/:machine_id/dashboard',
    name: 'node-dashboard',
    props: true,
    component: () => import(/* webpackChunkName: "node-dashboard" */ '../views/node-dashboard.vue')
  },
  {
    path: '/estor',
    name: 'estor',
    component: () => import(/* webpackChunkName: "estor" */ '../views/estor.vue')
  },
  {
    path: '/estor/spaces',
    name: 'estor-spaces',
    component: () => import(/* webpackChunkName: "estor-spaces" */ '../views/estor-spaces.vue')
  },
  {
    path: '/estor/config',
    name: 'estor-config',
    component: () => import(/* webpackChunkName: "estor-config" */ '../views/estor-config.vue')
  },
  {
    path: '/estor/jobs',
    name: 'estor-jobs',
    component: () => import(/* webpackChunkName: "estor-jobs" */ '../views/estor-jobs.vue')
  },
  {
    path: '/estor/history',
    name: 'estor-history',
    component: () => import(/* webpackChunkName: "estor-history" */ '../views/estor-history.vue')
  },
  {
    path: '/estor/import',
    name: 'estor-import',
    component: () => import(/* webpackChunkName: "estor-import" */ '../views/estor-import.vue')
  },
  {
    path: '/activate-node',
    name: 'activate-node',
    component: () => import(/* webpackChunkName: "activate-node" */ '../views/activate-node.vue')
  },
  {
    path: '/lzz/coupon-claim',
    name: 'coupon-claim',
    component: () => import(/* webpackChunkName: "coupon-claim" */ '../lzz/coupon.vue')
  },
  {
    path: '/lzz/discount-calc',
    name: 'discount-calc',
    component: () => import(/* webpackChunkName: "discount-calc" */ '../lzz/discount-calc.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
