<template>
  <router-view/>
</template>

<script>
export default {
  name: 'EuphonCloudApp',
  props: [],
  components: {
  },
  data: function () {
    return {
      server_url: 'https://euphon.cloud',
      estor_server: 'http://127.0.0.1:13000',
    };
  },
  computed: {
    is_home_page() {
      return this.$route.path == '/';
    },
  },
  methods: {
    headers() {
      return {};
    },
    api_post(path, data) {
      var url = this.server_url + path;
      return this.axios.post(url, data, {
        headers: this.headers(),
      })
    },
    api_get(path) {
      var url = this.server_url + path;
      return this.axios.get(url, {
        headers: this.headers(),
      })
    },
    estor_call: async function(cmd, params) {
      var data = params || {};
      data.command = cmd;
      const url = this.estor_server + "/api";
      var r = await this.axios.post(url, data);
      return r;
    },
    estor_put: async function(path, blob, progress_cb) {
      var url = this.estor_server + path;
      return await this.axios.put(url, blob, {
        headers: { 'Content-Type': 'application/octet-stream' },
        onUploadProgress: progress_cb,
      }).catch(e => { console.log(e); });
    },
  },
  mounted() {
  },
}
</script>

<style>
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}
div#app {
  height: 100%;
}
</style>
